import * as React from 'react';
import { useParams } from 'react-router-dom';

export default function Terms() {
    const { term_name }: any = useParams();


    React.useEffect(() => {
        var dest = "https://minibig.kr";
    
        switch (term_name) {
            case "policy_kor":
                dest = "https://minibig.notion.site/5bc7cbbe154b4d7ca4e959459df06b60";
                break;
            case "location_kor":
                dest = "https://minibig.notion.site/8f27901fa3374fb39d77bd1550a03bae";
                break;
            case "privacy_kor":
                dest = "https://minibig.notion.site/f6598a3fbc5945e9a55fe9b198be048a";
                break;
            case "marketing_kor":
                dest = "https://minibig.notion.site/c0796ee44a4a4af7810d1e28a7631304";
                break;
            case "policy_en":
                dest = "https://minibig.notion.site/Minibig-Terms-of-Use-1f9b8c5b44b14951ad619b3addbe2c0b";
                break;
            case "location_en":
                dest = "https://minibig.notion.site/Minibig-Terms-and-Conditions-of-Location-Based-Services-7af9cb6cd7b14cb288317641a55edf00";
                break;
            case "privacy_en":
                dest = "https://minibig.notion.site/Minibig-Privacy-Policy-48dcabf2e89048efb90d2f0a3a94bdf9";
                break;
            case "marketing_en":
                dest = "https://minibig.notion.site/Minibig-Promotional-Information-Use-Agreement-132b5912fa1d4e3c8add2ad21990976c";
                break;

            case "privacy_zh":
                dest = "https://minibig.notion.site/Privacy_ZH-10b237188f104d21aff75c584c58f41c";
                break;
            case "privacy_jp":
                dest = "https://minibig.notion.site/Privacy-JP-bb45ab19621a4ddb8a23fbd236ea8153";
                break;
            default:
                break;
        }
        console.log('dest: ', dest)
        const url = `${dest}`;
        window.location.href = url;
    }, [])

    return (
        <div>

        </div>
    );
}