import { useRoutes } from 'react-router-dom';
import Home from "../pages/Home";
import Terms from "../pages/Terms";

export default function Router() {

  return useRoutes([
    {
      path: '/',
      children: [
        { element: <Home />, index: true },
        {
          path: '/:term_name', element: <Terms />
        },

      ],
    }
  ])
}