import * as React from 'react';


export default function Home() {

    React.useEffect(() => {
        var dest = "https://minibig.kr";
        const url = `${dest}`;
        window.location.href = url;
    }, [])

    return (
        <div>

        </div>
    );
}